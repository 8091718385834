import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import React from "react";
import { Routes } from "constants/routes";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";
import { useSearchClassesForDashboard } from "../hooks";

export const MovesAndTechniques = () => {
  const {
    classResults: movesAndTechniquesClasses,
    loading,
  } = useSearchClassesForDashboard({
    defaultFilters: ["type:'Moves & Techniques'"],
  });

  if (movesAndTechniquesClasses?.length === 0) {
    return null;
  }

  return (
    <Section
      title="Moves & Techniques"
      showLoadingState={loading}
      headerLink={{
        route: `${Routes.results__classes}?refinementList[type][0]=Moves%20%26%20Techniques`,
        text: "See All",
      }}
    >
      <Slider
        data={movesAndTechniquesClasses}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.MovesAndTechniques}
            selectedFrom="MovesAndTechniques"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
